var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"campusLife"},[_c('TabPageLayout',{attrs:{"activeCode":"xiaoyuanshenghuo"}},[_c('div',{staticClass:"container clubTour"},[_c('div',{staticClass:"div_mod div_mod2 div_mod1"},_vm._l((_vm.blocks),function(block,index){return _c('div',{key:block.id,staticClass:"div_tit"},[_c('h2',[_vm._v(_vm._s(block.title))]),_c('div',{staticClass:"div_text",domProps:{"innerHTML":_vm._s(block.bz)}}),(index === 1)?_c('div',[_c('div',{staticClass:"lunbo"},[_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.blockArticles[index]),function(item){return _c('div',{key:item.id,staticClass:"swiper-slide"},[_c('a',[_c('img',{attrs:{"src":item.files,"alt":""}}),_c('p',[_vm._v(_vm._s(item.title))])])])}),0)]),_c('div',{staticClass:"direction"},[_c('span',{staticClass:"s_prev"},[_c('i')]),_c('span',{staticClass:"s_next"},[_c('i')])])])]):_vm._e(),(index === 2)?[(_vm.clubPics.length > 0)?_c('ul',{staticClass:"ul_ct"},_vm._l((_vm.clubPics),function(clubPic){return _c('li',{key:clubPic.id,style:({
                  backgroundImage: `url(${clubPic?.bgimg})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  height: '303px',
                }),on:{"click":function($event){return _vm.handleDialogShow(clubPic)}}},[_c('div',{staticClass:"divbox"},[_c('span',{staticClass:"s1"},[_vm._v(_vm._s(clubPic.title))]),_c('span',{staticClass:"s2"},[_vm._v("查看更多")])])])}),0):_vm._e()]:_vm._e()],2)}),0)])]),_c('CommonDialog',{attrs:{"visible":_vm.oDialog.visible,"titleText":_vm.oDialog.title,"showCancel":false,"showOK":false,"size":_vm.oDialog.size},on:{"update:visible":function($event){return _vm.$set(_vm.oDialog, "visible", $event)},"update:titleText":function($event){return _vm.$set(_vm.oDialog, "title", $event)},"update:title-text":function($event){return _vm.$set(_vm.oDialog, "title", $event)}}},[(_vm.oDialog.visible)?_c('SwiperContainer',{attrs:{"typeId":_vm.oDialog.typeId,"showContent":""}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }